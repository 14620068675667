import React from 'react';
import styled from 'styled-components';

import { Link } from 'gatsby';

const Container = styled.div`
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    height: 60px;
    background-color: white;
    color: black;
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    padding: 5px 20px 5px 20px;
`;

const Logo = styled(Link)`
    color: black;
`;

const MenuItem = styled.span`
    padding: 0 10px 0 10px;
`;

export default ({ children, className }) => {
    return (
        <Container className={`top-menu-container ${className}`}>
            {/* <Logo className='top-menu-logo'
                to="/"
            >
                Guillaume Pannetier Photography
            </Logo> */}
            <nav className="top-menu-nav">
                {children.map((child, index) => {
                    return <MenuItem key={index}>{child}</MenuItem>;
                })}
            </nav>
        </Container>
    );
};
