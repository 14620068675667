import React from "react";
import styled from "styled-components";

const Popin = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    color: black;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
`;

const CloseButton = styled.a`
    background: black;
    padding: 0 40px;
    color: #CCCCCC;
    border-radius: 40px;
    cursor: pointer;
    margin: 5px 10px;

    @media (max-width: 768px) {
        color: #FFFFFF;
    }

    &:hover {
        color: #FFFFFF;
    }

    & > span {
        padding: 0 24px;
    }
`;

const CookieWidget = ({onClose}) => {
    return (
        <Popin>
            <span>
                En poursuivant sur ce site, vous acceptez l'utilisation de cookies.
            </span>
            <CloseButton onClick={onClose}><span>J'accepte</span></CloseButton>
        </Popin>
    );
};

export default CookieWidget;
