import React, { useState } from 'react';
import { Link } from 'gatsby';
import { useCookies } from 'react-cookie';
import PropTypes from 'prop-types';

import _Menu from './SlideMenu';
import _TopMenu from './TopMenu';
import Footer from './Footer';
import styled from 'styled-components';

import '../assets/scss/main.scss';

import SEO from './SEO';
import CookieWidget from './CookieWidget';

const LayoutWrapper = styled.div`
    padding: 5rem 5rem 0 5rem;
    margin-bottom: 20px;

    @media (max-width: 768px) {
        padding: 5rem 2rem 0 2rem;
    }

    color: #dddddd;
`;

const HomeLayoutWrapper = styled(LayoutWrapper)`
    padding: 0 5rem !important;
`;

const TopMenu = styled(_TopMenu)`
    @media (max-width: 768px) {
        display: none;
    }
`;

const SlideMenu = styled(_Menu)`
    @media (min-width: 768px) {
        display: none;
    }
`;

const getMenuLinks = () => [
    <Link to="/" key="home" title="Accueil">
        Accueil
    </Link>,
    <Link to="/gallery/portrait" key="portrait" title="Portrait">
        Portrait
    </Link>,
    <Link to="/gallery/wedding" key="mariage" title="Mariage">
        Mariage
    </Link>,
    <Link to="/gallery/dance" key="danse" title="Danse">
        Danse
    </Link>,
    <Link to="/contact" key="contact" title="Me contacter">
        Contact
    </Link>,
    <Link to="/about" key="about" title="A propos de moi">
        A propos
    </Link>,
    <a
        key="instagram"
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.instagram.com/guillaumepannetierphotography/"
        title="Instagram"
    >
        <span>
            <i className="fa fa-instagram" />
        </span>
    </a>,
    <a
        key="facebook"
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.facebook.com/guillaumepannetierphotography/"
        title="Facebook"
    >
        <span>
            <i className="fa fa-facebook" />
        </span>
    </a>
];

const Layout = ({ children, location }) => {
    const gdprCookieName =
        'gatsby-plugin-google-analytics-gdpr_cookies-enabled';
    const [cookies, setCookie, removeCookie] = useCookies([gdprCookieName]);
    const [cookieWidgetOpened, setCookieWidgetOpened] = useState(
        cookies[gdprCookieName] === 'false'
    );

    const acceptCookies = () => {
        setCookieWidgetOpened(false);
        setCookie(gdprCookieName, 'true');
    };

    const menuLinks = getMenuLinks(location.pathname);

    let title = null;

    switch (location.pathname) {
        case '/gallery/dance':
        case '/gallery/dance/':
            title = 'Photographe danse Montpellier';
            break;

        case '/gallery/portrait':
        case '/gallery/portrait/':
            title = 'Photographe portrait lifestyle Montpellier';
            break;

        case '/gallery/wedding':
        case '/gallery/wedding/':
            title = 'Photographe mariage Montpellier';
            break;

        case '/about':
        case '/about/':
            title = 'À propos';
            break;

        case '/contact':
        case '/contact/':
            title = 'Me contacter';
            break;

        case '/':
        default:
            title = null;
            break;
    }

    const Wrapper =
        location.pathname !== '/' ? LayoutWrapper : HomeLayoutWrapper;

    return (
        <>
            <SEO title={title} />

            {location.pathname !== '/' && (
                <>
                    <SlideMenu>{menuLinks}</SlideMenu>
                    <TopMenu>{menuLinks}</TopMenu>
                </>
            )}

            <Wrapper id="page-wrapper" className="page">
                {children}
                {location.pathname !== '/' && <Footer />}
            </Wrapper>
            {cookieWidgetOpened && (
                <CookieWidget onClose={acceptCookies} />
            )}
        </>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired
};

export default Layout;
