import React from 'react';
import { push as Menu } from 'react-burger-menu';

import '../assets/scss/components/_menu.scss';

export default ({ pageWrapId, isOpen, children, className }) => {
    const styles = {
        bmCross: {
            background: '#ffffff'
          }
    };
    return (
            <Menu className={`slide-menu ${className}`}
                isOpen={isOpen}
                pageWrapId={pageWrapId}
                styles={styles}
            >
                { children }
            </Menu>
    );
};
